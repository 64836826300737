import { BehaviorSubject } from "rxjs";
import ApiClient from "../../../shared/api/ApiClient";
import { SearchStoreMasterParams, StoreMasterParams, StoreType } from "../models/store";
import { CreateStoreManagementFormValues } from "../components/CreateStoreModal";
import { UpdateStoreFormValues } from "../UpdateStore";

const GET_STORE = "/v2/stores/:store_number";
const UPDATE_STORE = "/v2/stores/:store_number";
const DELETE_STORE = "/v2/stores/:store_number";
const CREATE_STORE = "/v2/stores";
const SEARCH_STORE_MASTER = "/v2/stores/search";
const UPLOAD_STORE_MASTER = "v2/stores/master/upload";
const ENABLE_SEND_NOTI = "/v2/stores/notification/enable";
const DISABLE_SEND_NOTI = "/v2/stores/notification/disable";
const ENABLE_SEND_NOTI_ALL = "/v2/stores/notification/enable-all";
const DISABLE_SEND_NOTI_ALL = "/v2/stores/notification/disable-all";
const DOWNLOAD_STORE_MASTER = "/v2/stores/download";
const TOGGLE_CHECKING_GEOFENCE = "v2/stores/:store_number/checking-geofence";

const getStore = (storeNumber: string): Promise<StoreType> =>
  ApiClient.get(GET_STORE.replace(":store_number", storeNumber)).then((res) => res.data);
const createStore = (params: CreateStoreManagementFormValues): Promise<StoreType> =>
  ApiClient.post(CREATE_STORE, params).then((res) => res.data);

const searchStoreMaster = (params: SearchStoreMasterParams): Promise<any> =>
  ApiClient.post(SEARCH_STORE_MASTER, params).then((res) => {
    return res.data;
  });

const updateStore = (storeNumber: string, updateStore: UpdateStoreFormValues): Promise<StoreType> => {
  return ApiClient.put(UPDATE_STORE.replace(":store_number", storeNumber), updateStore).then((res) => {
    return res.data;
  });
};

const deleteStore = (storeNumber: string): Promise<any> =>
  ApiClient.delete(DELETE_STORE.replace(":store_number", storeNumber)).then((res) => {
    return res.data;
  });

const uploadStoreMaster = async (originFileObj: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", originFileObj);
  const res = await ApiClient.post(UPLOAD_STORE_MASTER, formData, { timeout: 3600000 });
  return res.data;
};

const enableSendNotification = (params: string[]): Promise<any> =>
  ApiClient.post(ENABLE_SEND_NOTI, params).then((res) => {
    return res.data;
  });

const disableSendNotification = (params: string[]): Promise<any> =>
  ApiClient.post(DISABLE_SEND_NOTI, params).then((res) => {
    return res.data;
  });

const enableSendNotificationAll = (params: StoreMasterParams): Promise<any> =>
  ApiClient.post(ENABLE_SEND_NOTI_ALL, params, { timeout: 1800000 }).then((res) => {
    return res.data;
  });

const disableSendNotificationAll = (params: StoreMasterParams): Promise<any> =>
  ApiClient.post(DISABLE_SEND_NOTI_ALL, params, { timeout: 1800000 }).then((res) => {
    return res.data;
  });

const downloadStoreMasterFile = (param: StoreMasterParams): Promise<any> =>
  ApiClient.post(DOWNLOAD_STORE_MASTER, param, {
    responseType: "blob",
  }).then((res) => res);

const toggleCheckingGeofence = (storeNumber: string, enabled: boolean): Promise<any> =>
  ApiClient.put(TOGGLE_CHECKING_GEOFENCE.replace(":store_number", storeNumber), { enabled: enabled }).then(
    (res) => {
      return res.data;
    },
  );

const isloadingTable = new BehaviorSubject<boolean>(false);
const signalNotiFail = new BehaviorSubject<boolean>(false);
const signalResetForm = new BehaviorSubject<boolean>(false);
const storeUpdated = new BehaviorSubject<StoreType | null>(null);
const signalDeleteStore = new BehaviorSubject<boolean>(false);

const storeMasterService = {
  getStore,
  updateStore,
  createStore,
  deleteStore,
  searchStoreMaster,
  uploadStoreMaster,
  enableSendNotification,
  disableSendNotification,
  enableSendNotificationAll,
  disableSendNotificationAll,
  downloadStoreMasterFile,
  toggleCheckingGeofence,
  isloadingTable,
  signalNotiFail,
  signalResetForm,
  storeUpdated,
  signalDeleteStore,
};

export default storeMasterService;
